import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromCoreFeature from './core/store/core.feature';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { TrimWhitespacePipe } from './core/pipes/trimeWhiteSpace';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TrimWhitespacePipe,
    RouterModule,
    MatIconModule,
    MatNativeDateModule,
    StoreModule.forFeature(
      fromCoreFeature.featureKey,
      fromCoreFeature.reducers
    ),
    EffectsModule.forFeature(fromCoreFeature.effects),
  ],
  exports: [CommonModule, RouterModule, MatIconModule, TrimWhitespacePipe],
})
export class CoreModule {}
