import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Subject } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { skillState } from './skill.reducer';
import {
  getSkills,
  addSkills,
  removeSkill,
  updateSkills,
} from './skill.actions';
import { Skill } from '../../models/project/skill';
import { BaseFacade } from '../base.facade';

@Injectable({
  providedIn: 'root',
})
export class SkillFacade extends BaseFacade {
  skills$ = this._store.pipe(
    select(skillState),
    map((state) => state.skills)
  );

  constructor(_store: Store<AppState>) {
    super(_store);
  }

  getSkills$(callback?: (skills: Skill[]) => void) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getSkills({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addSkills(skills: Skill[]) {
    return this.dispatchAction$(addSkills, { skills });
  }

  removeSkill(skillId: string) {
    return this.dispatchAction$(removeSkill, { skillId });
  }

  updateSkills(skills: Skill[]) {
    return this.dispatchAction$(updateSkills, { skills });
  }
}
