import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable, tap } from 'rxjs';

export interface IPreventNavigationComponent {
  canDeactivate: () => boolean | Observable<boolean>;
  showNavigationPopup: () => Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class PreventNavigationGuard implements CanDeactivate<unknown> {
  canDeactivate(
    component: IPreventNavigationComponent
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return component.canDeactivate() ? true : component.showNavigationPopup();
  }
}
