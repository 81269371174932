import { AADUser } from './aadUser';
import { Group } from './group';
export class GroupUser {
  user?: AADUser;
  group?: Group;
  get displayName() {
    return this.user ? this.user?.displayName : this.group?.displayName;
  }
  get id() {
    return this.user ? this.user?.id : this.group?.groupId;
  }

  constructor(obj: Partial<GroupUser>) {
    Object.assign(this, obj);
  }
}
