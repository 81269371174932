import * as fromProject from './project/project.reducer';
import * as fromSkill from './skill/skill.reducer';
import * as fromProduct from './product/product.reducer';
import * as fromRate from './rate/rate.reducer';
import * as fromProjectType from './project-type/project-type.reducer';
import * as fromProjectEstimation from './projectEstimation/projectEstimation.reducer';
import * as fromProjectTypeLine from './project-type-line/project-type-line.reducer';
import * as fromProjectMVPBlockTemplate from './projectMVPBlockTemplate/projectMVPBlockTemplate.reducer';
import * as fromProjectMVP from './projectMVP/projectMVP.reducer';
import * as fromProjectMVPBlockTemplateLine from './projectMVPBlockTemplateLine/projectMVPBlockTemplateLine.reducer';
import * as fromProjectEstimationParam from './projectEstimationParam/projectEstimationParam.reducer';
import * as fromProjectEstimationParamFixedPercent from './projectEstimationParamFixedPercent/projectEstimationParamFixedPercent.reducer';
import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { ProjectEffects } from './project/project.effects';
import { SkillEffects } from './skill/skill.effects';
import { ProductEffects } from './product/product.effect';
import { RateEffects } from './rate/rate.effect';
import { ProjectTypeEffects } from './project-type/project-type.effects';
import { ProjectMVPBlockTemplateEffects } from './projectMVPBlockTemplate/projectMVPBlockTemplate.effects';
import { ProjectMVPBlockTemplateLineEffects } from './projectMVPBlockTemplateLine/projectMVPBlockTemplateLine.effects';
import { ProjectTypeLineEffects } from './project-type-line/project-type-line.effects';
import { ProjectEstimationParamEffects } from './projectEstimationParam/projectEstimationParam.effects';
import { ProjectEstimationParamFixedPercentEffects } from './projectEstimationParamFixedPercent/projectEstimationParamFixedPercent.effects';
import { ProjectEstimationEffects } from './projectEstimation/projectEstimation.effects';
import { ProjectMVPEffects } from './projectMVP/projectMVP.effects';

export const featureKey = 'coreFeature';

export interface CoreState {
  [fromProject.featureSlice]: fromProject.State;
  [fromSkill.featureSlice]: fromSkill.State;
  [fromProduct.featureSlice]: fromProduct.State;
  [fromRate.featureSlice]: fromRate.State;
  [fromProjectType.featureSlice]: fromProjectType.State;
  [fromProjectTypeLine.featureSlice]: fromProjectTypeLine.State;
  [fromProjectMVPBlockTemplate.featureSlice]: fromProjectMVPBlockTemplate.State;
  [fromProjectMVPBlockTemplateLine.featureSlice]: fromProjectMVPBlockTemplateLine.State;
  [fromProjectEstimationParam.featureSlice]: fromProjectEstimationParam.State;
  [fromProjectEstimationParamFixedPercent.featureSlice]: fromProjectEstimationParamFixedPercent.State;
  [fromProjectEstimation.featureSlice]: fromProjectEstimation.State;
  [fromProjectMVP.featureSlice]: fromProjectMVP.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromProject.featureSlice]: fromProject.Reducer,
      [fromSkill.featureSlice]: fromSkill.Reducer,
      [fromProduct.featureSlice]: fromProduct.Reducer,
      [fromRate.featureSlice]: fromRate.rateReducer,
      [fromProjectType.featureSlice]: fromProjectType.projectTypeReducer,
      [fromProjectTypeLine.featureSlice]:
        fromProjectTypeLine.projectTypeLineReducer,
      [fromProjectMVPBlockTemplate.featureSlice]:
        fromProjectMVPBlockTemplate.projectMVPBlockTemplateReducer,
      [fromProjectMVPBlockTemplateLine.featureSlice]:
        fromProjectMVPBlockTemplateLine.projectMVPBlockTemplateLineReducer,
      [fromProjectEstimationParam.featureSlice]:
        fromProjectEstimationParam.projectEstimationParamReducer,
      [fromProjectEstimationParamFixedPercent.featureSlice]:
        fromProjectEstimationParamFixedPercent.projectEstimationParamFixedPercentReducer,
      [fromProjectEstimation.featureSlice]:
        fromProjectEstimation.projectEstimationReducer,
      [fromProjectMVP.featureSlice]: fromProjectMVP.projectMVPReducer,
    }),
  }
);
export const effects = [
  ProjectEffects,
  SkillEffects,
  ProductEffects,
  RateEffects,
  ProjectTypeEffects,
  ProjectTypeLineEffects,
  ProjectMVPBlockTemplateEffects,
  ProjectMVPBlockTemplateLineEffects,
  ProjectEstimationParamEffects,
  ProjectEstimationParamFixedPercentEffects,
  ProjectEstimationEffects,
  ProjectMVPEffects,
];
