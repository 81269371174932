export class Status {
  ishtarStatusId!: string;
  status!: string;
  color?: string;
  isFinalState?: boolean;
  type?: string[];

  constructor(obj: Partial<Status>) {
    Object.assign(this, obj);
  }
}
