import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimWhitespace',
  pure: false,
  standalone: true
})
export class TrimWhitespacePipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/\s/g, '');
  }
}
